// @flow
import React from 'react';
import { Link } from 'gatsby';
import Author from './Author';
import CallToAction from './CallToAction';
import Comments from './Comments';
import Content from './Content';
import Meta from './Meta';
import Tags from './Tags';
import CompanyLogo from './CompanyLogo/Author';
import { useSiteMetadata } from '../../hooks';
import styles from './Post.module.scss';
import logoStyles from '../Sidebar/Sidebar.module.scss';
import type, { Node } from '../../types';

type Props = {
  post: Node
};

const Post = ({ post }: Props) => {
  const { html } = post;
  const { tagSlugs, slug } = post.fields;
  const { tags, title, date, author: postAuthor } = post.frontmatter;
  const { author, copyright, menu } = useSiteMetadata();

  return (
    <div className={styles['post']}>
      <CompanyLogo author={author} isIndex={false} />
      <Link className={styles['post__home-button']} to="/">
        All Articles
      </Link>
      <div
        className={styles['post__home-button']}
        style={{ visibility: 'hidden' }}
      ></div>
      <div className={styles['post__content']}>
        <Content body={html} title={title} />
        <CallToAction />
      </div>

      <div className={styles['post__footer']}>
        <Meta date={date} />
        {tags && tagSlugs && <Tags tags={tags} tagSlugs={tagSlugs} />}
        <Author name={postAuthor} />
      </div>
      <div className={styles['post__comments']}>
        <Comments postSlug={slug} postTitle={post.frontmatter.title} />
      </div>
    </div>
  );
};

export default Post;
