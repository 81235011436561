// @flow
import React from 'react';
import { getAuthorInfo } from '../../../utils';
import styles from './Author.module.scss';

const Author = (props) => {
  const { name } = props;
  const author = getAuthorInfo(name);

  return (
    <div className={styles['author']}>
      <p className={styles['author__bio']}>
        Written by
        <a
          href={author.link}
          rel="noopener noreferrer"
          target="_blank"
        >
          <strong> {author.name}</strong>
        </a>
      </p>
    </div>
  );
};

export default Author;
