// @flow
import React from 'react';
import styles from './Author.module.scss';

const Author = () => (
    <div className={styles['author']}>
     <p className={styles['author__action']}>
        Like what you've read?
        <a
          href="https://tildeloop.com/subscribe"
          rel="noopener noreferrer"
          target="_blank"
        >
        {' '}Join our newsletter
        </a>
      </p>
      <p className={styles['author__action']}>
        Launching a new JavaScript project? Need help on an existing project?
        <a
          href="https://tildeloop.com/#contact"
          rel="noopener noreferrer"
          target="_blank"
        >
        {' '}Work with us
        </a>
      </p>
    </div>
);

export default Author;
