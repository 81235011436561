// @flow
const getAuthorInfo = (authorName: string) => {
  const info: { name: string, link: string, bio: string } = {};

  switch (authorName) {
    case "Marin Radman":
      info.name = authorName;
      info.link = "https://www.linkedin.com/in/marin-radman-598471137/";
      info.bio = "";
      break;
    case "Bruno Ziterbart":
      info.name = authorName;
      info.link = "https://www.linkedin.com/in/bruno-ziterbart/";
      info.bio = "";
      break;
    case "Marija Ziterbart":
      info.name = authorName;
      info.link = "https://www.linkedin.com/in/marija-ziterbart/";
      info.bio = "";
      break;
    default:
      info.name = "Tilde Loop";
      info.link = "https://tildeloop.com/";
      info.bio = "";
      break;
  }

  return info;
};

export default getAuthorInfo;
